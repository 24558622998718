@import '~antd/dist/antd.less';

.App {
  text-align: left;
  background-color: #014890;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-color {
  color: #014890 !important
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-layout-header {
  background-color: #fff;
  border-bottom: 1px solid #dddddd;
  padding: 0 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .ant-layout-content {
    padding: 0 !important;
  }
}

.site-layout-content {
  min-height: 320px;
  padding: 30px;
  background: #fff; 
  margin: 0 auto;
  max-width: 900px;
  // min-height: 90vh;
  // overflow: auto;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

body {
  background-color: #f0f2f5;
}

.logo {
  max-width: 900px;
  margin: 0 auto;
}

.play-store-img {
  width: 90%;
  max-width: 148px;
}

.app-store-img {
  width: 100%;
  max-width: 150px;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #fff;
}

@media only screen and (max-width: 600px) {
  .crop-container{
    height: 500px;
  }
}

.info-container {
  background-color: #c6e1fc;
  padding: 15px 15px 1px 15px;
  margin-bottom: 15px;
}

.loader-container {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
}
@primary-color: #303f9f;